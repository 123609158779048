<!--
 * @Descripttion: 户型管理
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-13 09:49:53
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-27 11:40:27
-->
<template>
    <div class="familyManagement">
        <div class="add-wrap">
            <div class="search-wrap">
                <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                <el-button @click="clickSearch" type="primary">搜索</el-button>
            </div>
            <el-button @click="clickShowScene" class="btns" type="primary">添加户型</el-button>
        </div>
        <div class="table-wrap" v-loading="loading">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column align="center" prop="id" label="ID" width="60"></el-table-column>
                <el-table-column align="center" prop="img_url" label="户型图片" width="80">
                    <template slot-scope="scope">
                        <img :src="scope.row.img_url" height="30" />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="shop.name" label="商家名称"></el-table-column>
                <el-table-column align="center" prop="name" label="小区名称">
                    <template slot-scope="scope">
                        <div v-if="scope.row.scene_residential_district != null">
                            {{scope.row.scene_residential_district.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="户型名称" ></el-table-column>
                <el-table-column align="center" prop="layout" label="户型布局" ></el-table-column>
                <el-table-column align="center" prop="area" label="户型面积" ></el-table-column>
                <el-table-column align="center" prop="create_timex" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" label="操作" width="90">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :visible.sync="isShowScene" 
            @close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title" v-if="editId<0">添加户型</div>
            <div class="title" v-else>编辑户型</div>
            <div class="content" v-loading="dialogLoading">
                <el-form :model="plotForm" :rules="plotRules" ref="plotForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="选择品牌" prop="ep_id">
                        <el-select @change="selectChange" 
                        v-model="plotForm.ep_id" placeholder="请选择品牌">
                            <el-option
                                v-for="item in epOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择商家" prop="shop_id">
                        <el-select filterable @change="shopChange"  v-model="plotForm.shop_id"
                         placeholder="请选择商家(先选择品牌)">
                            <el-option
                                v-for="item in shopOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择小区" prop="srd_id">
                        <el-select filterable clearable v-model="plotForm.srd_id"
                         placeholder="请选择小区(先选择商家)">
                            <el-option
                                v-for="item in srdOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="户型名称" prop="name">
                        <el-input v-model="plotForm.name" placeholder="请输入户型名称"></el-input>
                    </el-form-item>
                    <el-form-item label="户型布局" prop="layout">
                        <el-input v-model="plotForm.layout" placeholder="请输入户型布局"></el-input>
                    </el-form-item>
                    <el-form-item label="户型面积" prop="area">
                        <el-input v-model="plotForm.area" placeholder="请输入户型面积"></el-input>
                    </el-form-item>
                    <el-form-item label="户型图片" prop="img_url">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false"
                                :btnDisplay="image.length<1" listType="picture-card"
                                v-model="image">
                            </aliyun-upload>
                            <div v-if="image.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="image" :src="image[0]"/>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="btn-wrap">
                <el-button v-if="editId<0" :loading="btnLoading" @click="creationPaves" type="primary">添加</el-button>
                <el-button v-else :loading="btnLoading" @click="editPaves" type="primary">编辑</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    data () {
        return {
            loading: false,
            dialogLoading: false,
            tableData: [],
            total: 0,
            currentPage: 1,
            isShowScene: false,
            keyword: '',
            epOptions: [],
            shopOptions: [],
            srdOptions: [],
            plotForm: {
                ep_id: '',
                shop_id: '',
                srd_id: '',
                name: '',
                area: '',
                img_url: '',
                layout: ''
            },
            plotRules: {
                ep_id: [
                    { required: true, message: '请选择品牌', trigger: 'change' },
                ],
                shop_id: [
                    { required: true, message: '请选择商家', trigger: 'change' },
                ],
                srd_id: [
                    { required: true, message: '请选择小区', trigger: 'change' },
                ],
                name: [
                    { required: true, message: '请输入户型名称', trigger: 'blur' },
                ],
                layout: [
                    { required: true, message: '请输入户型布局', trigger: 'blur' },
                ],
                area: [
                    { required: true, message: '请输入户型面积', trigger: 'blur' },
                ],
                img_url: [
                    { required: true, message: '请上传图片', trigger: 'blur' },
                ],
            },
            image: [],
            btnLoading: false,
            editId: -1
        }
    },
    watch: {
        image(newData, oldData) {
            this.plotForm.img_url = newData[0]
        }
    },
    mounted () {
        this.getHouseTypeIndex();
        this.getEpConditions();
    },
    methods: {
        // 选择小区
        shopChange(){
            this.plotForm.srd_id = '';
            this.dialogLoading = true;
            this.getHouseType();
        },
        // 选择商家
        selectChange(){
            this.plotForm.shop_id = '';
            this.plotForm.srd_id = '';
            this.dialogLoading = true;
            this.getShopList();
        },
        // 获取小区数据
        getHouseType(){
            common.connect("/customerservicev1/scene/residentialConditions",{shop_id: this.plotForm.shop_id},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.name
                }
                })
                this.srdOptions = resultSecond;
                this.dialogLoading = false;
            });
        },
        // 获取商家数据
        getShopList(){
            common.connect("/customerservicev1/shop/allShopConditions",{ep_id: this.plotForm.ep_id},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.name
                }
                })
                this.shopOptions = resultSecond;
                this.dialogLoading = false;
            });
        },
        // 获取品牌数据
        getEpConditions(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.ep_id,
                    label: v.name
                }
                })
                this.epOptions = resultSecond;
            });
        },
        // 创建
        creationPaves(){
            this.$refs.plotForm.validate((valid) => {
                if (valid) {
                    console.log(this.plotForm);
                    this.btnLoading = true;
                    common.connect('/customerservicev1/scene/storeHouseType',this.plotForm,(res)=>{
                        this.getHouseTypeIndex();
                        this.isShowScene = false;
                        this.btnLoading = false;
                        this.image = [];
                    })
                    setTimeout(()=>{
                        this.btnLoading = false;
                    },3000)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑
        editPaves(){
            this.$refs.plotForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    this.plotForm.id = this.editId;
                    common.connect('/customerservicev1/scene/updateHouseType',this.plotForm,(res)=>{
                        this.getHouseTypeIndex();
                        this.isShowScene = false;
                        this.btnLoading = false;
                        this.image = [];
                        this.editId = -1;
                    })
                    setTimeout(()=>{
                        this.btnLoading = false;
                    },3000)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            common.connect('/customerservicev1/scene/editHouseType',{id: row.id},(res)=>{
                this.isShowScene = true;
                this.image[0] = res.data.img_url;
                this.plotForm = res.data;
                this.getShopList();
                this.getHouseType();
            })
        },
        // 删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该户型, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/customerservicev1/scene/deleteHouseType',{id: row.id},(res)=>{
                    this.getHouseTypeIndex();
                    this.$message.success('删除成功！')
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 获取户型列表数据
        getHouseTypeIndex(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword
            }
            this.loading = true;
            common.connect('/customerservicev1/scene/houseTypeIndex',params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            })
        },
        // 搜索
        clickSearch(){
            this.getHouseTypeIndex();
        },
        delImageList(){
            this.image.splice(0, 1)
        },
        clickShowScene(){
            this.isShowScene = true;
        },
        // 关闭弹窗
        handleClose(){
            let plotForm = {
                ep_id: '',
                shop_id: '',
                srd_id: '',
                name: '',
                area: '',
                img_url: '',
                layout: '',
            }
            this.isShowScene = false;
            this.editId = -1;
            this.image = [];
            this.plotForm = plotForm;
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getHouseTypeIndex();
        }
    },
}
</script>

<style lang='scss'>
.familyManagement{
    .add-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .search-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .el-input{
                width: 200px;
                margin-right: 10px;
            }
        }
    }
    .table-wrap{
        margin-top: 20px;
        .image{
            width: 40px;
            cursor: pointer;
        }
        .operation-wrap{
            display: flex;
            justify-content: center;
            .iconfont{
                font-size: 16px;
                padding: 0 10px;
                cursor: pointer;
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }
    .dialog-wrap{
        width: 700px;
        .title{
            font-size: 16px;
            color: #000;
            margin-left: 20px;
        }
        .el-select{
            width: 100%;
        }
        .content{
            margin: 40px 20px 20px 20px;
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            padding: 15px 0;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>

