<!--
 * @Descripttion: 小区户型管理
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-13 09:41:49
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-13 14:07:24
-->
<template>
    <div class="housingTypeManagement">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">小区户型管理</div>
        </div>
        <div class="case-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="小区管理" name="first">
                    <communityManagement ref="communityManagement"></communityManagement>
                </el-tab-pane>
                <el-tab-pane label="户型管理" name="second">
                    <familyManagement ref="familyManagement"></familyManagement>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import communityManagement from './htComponents/communityManagement'
import familyManagement from './htComponents/familyManagement'
export default {
    components:{
        communityManagement,
        familyManagement,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'housingTypeManagement',
            activeName: 'first',
        }
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang='scss'>
.housingTypeManagement{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
    }
}
</style>
